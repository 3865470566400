var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-settings"},[_vm._m(0),_c('div',{staticClass:"moderator-user-settings__content"},[_c('div',{staticClass:"moderator-user-settings__row moderator-user-settings__row_big"},[_c('div',{staticClass:"moderator-user-settings__column main-color"},[_c('div',{staticClass:"moderator-user-settings__author"},[_c('div',{staticClass:"moderator-user-settings__info"},[_vm._m(1),_c('div',{staticClass:"moderator-user-settings__name"},[_c('p',[_vm._v(" "+_vm._s(_vm.currentUser.surname)+" "+_vm._s(_vm.currentUser.name)+" "+_vm._s(_vm.currentUser.email)+" ")]),_c('ul',{staticClass:"main-table__list"},_vm._l((_vm.currentUser.availableCountries),function(country){return _c('li',{key:country},[_c('div',{staticClass:"main-table__icon"},[_c('img',{attrs:{"src":require(`@/assets/images/icons/flags/${country.toLowerCase()}.svg`),"alt":""}})]),_c('p',{staticClass:"main-table__text main-table__text_bold"},[_vm._v(" "+_vm._s(country)+" ")])])}),0)])]),_c('div',{staticClass:"moderator-user-settings__info"},[_c('ul',{staticClass:"moderator-user-settings__roles"},_vm._l((_vm.currentUser.roles),function(role){return _c('li',{key:role,style:({
                  background:
                    role == 'администратор'
                      ? '#FFDDDD'
                      : role == 'модератор'
                      ? '#C0FBFF'
                      : '#D0FFD2',
                })},[_c('p',[_vm._v(_vm._s(role))])])}),0)])])]),_vm._m(2)]),_c('div',{staticClass:"moderator-user-settings__row"},[_c('div',{staticClass:"moderator-user-settings__column main-color"},[_c('h5',{staticClass:"moderator-user-settings__subtitle"},[_vm._v(" Настройки интерфейса ")]),_vm._m(3),_c('div',{staticClass:"moderator-user-settings__block moderator-user-settings__block_direction"},[_c('p',{staticClass:"moderator-user-settings__parameter"},[_vm._v("Часовой пояс:")]),_vm._m(4),_c('div',{staticClass:"moderator-user-settings__checkbox"},[_c('div',{staticClass:"main-checkbox",on:{"click":function($event){_vm.checkbox = !_vm.checkbox}}},[_c('input',{staticClass:"main-checkbox__input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.checkbox}}),_c('div',{staticClass:"main-checkbox__checkmark"}),_c('div',{staticClass:"checkbox__body"})]),_c('p',[_vm._v(" Автомотически устанавливать часовой пояс в зависимости от текущей геопозиции ")])])]),_c('div',{staticClass:"moderator-user-settings__block"},[_c('div',{staticClass:"moderator-user-settings__parameter"},[_c('p',[_c('SunIcon'),_vm._v(" Ночной режим")],1)]),_c('div',{staticClass:"moderator-user-settings__value"},[_c('input',{staticClass:"main-switch",attrs:{"type":"checkbox"},domProps:{"checked":_vm.night},on:{"click":_vm.changeMode}})])])])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-settings__title"},[_c('h3',[_vm._v("Настройки")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-settings__image"},[_c('img',{attrs:{"src":require("@/assets/images/users/avatar1.png"),"alt":"avatar"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-settings__column main-color"},[_c('h5',{staticClass:"moderator-user-settings__subtitle"},[_vm._v("Пароль")]),_c('div',{staticClass:"moderator-user-settings__password"},[_c('div',{staticClass:"moderator-user-settings__field"},[_c('label',{attrs:{"for":"moderatorUserSettingsPassword"}},[_vm._v("Введите новый пароль:")]),_c('input',{attrs:{"type":"password","id":"moderatorUserSettingsPassword"}})]),_c('div',{staticClass:"moderator-user-settings__field"},[_c('label',{attrs:{"for":"moderatorUserSettingsPasswordRepeat"}},[_vm._v("Подтвердите пароль:")]),_c('input',{attrs:{"type":"password","id":"moderatorUserSettingsPasswordRepeat"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-settings__block"},[_c('p',{staticClass:"moderator-user-settings__parameter"},[_vm._v("Язык")]),_c('select',{staticClass:"moderator-user-settings__select moderator-user-settings__value",attrs:{"name":"language"}},[_c('option',{attrs:{"value":"RUS"}},[_vm._v("RUS")]),_c('option',{attrs:{"value":"EN"}},[_vm._v("EN")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-settings__value"},[_c('select',{staticClass:"moderator-user-settings__select moderator-user-settings__select_long",attrs:{"name":"timeZone"}},[_c('option',{attrs:{"value":"RUS"}},[_vm._v("(GMT + 02%00) Riga")]),_c('option',{attrs:{"value":"EN"}},[_vm._v("(GMT + 02%00) Riga")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-submit"},[_c('button',[_vm._v("Сохранить")])])
}]

export { render, staticRenderFns }